<template>
  <div class="shadowTicket">
    <van-notice-bar
      wrapable
      :scrollable="false"
      color="#FD4D4F"
      text="温馨提示：仅出售当日影票，请理性购票，电影票一经售出恕不退票！感谢您的理解。"
    />
    <div class="date_box">
      <div
        v-for="(item, index) in dateList"
        :key="index"
        class="date_item"
        @click="onDate(index)"
      >
        <span>{{ item.dayTit }}</span>
        <div :class="{ dateActive: dateActive === index }">{{ item.date }}</div>
      </div>
    </div>
    <!-- <van-tabs
      v-model:active="tabsActive"
      color="#0B6CF9"
      title-active-color="#0B6CF9"
    >
      <van-tab
        :title="item.cinemaName"
        v-for="item in tabsList"
        :key="item.cinemaName"
        :name="item.cinemaName"
      ></van-tab>
    </van-tabs> -->
    <template v-if="data && data.channel === 1">
      <template v-for="(item, index) in ticketList" :key="index">
        <div class="ticket_item">
          <img :src="item.cover" alt="" />
          <div class="ticket">
            <h4>{{ item.filmName }}</h4>
            <div class="btn_box">
              <p>
                <span>余票：{{ item.seatNum }}</span>
                <span
                  >票价：<span style="color: #fd4d4f"
                    >￥{{ item.filmPrice }}</span
                  ></span
                >
              </p>
              <van-button
                color="#076cfa"
                size="mini"
                @click="onReservation(item)"
                >立即购买</van-button
              >
            </div>
            <p>播放时间：{{ item.startTime + ' - ' + item.endTime }}</p>
            <MyMore :moreData="item" @onMore="onMore" />
          </div>
        </div>
      </template>
      <div v-show="ticketList.length === 0" class="nothing">
        <img src="@/assets/images/nothing.png" alt="" />
        <span>暂无影片</span>
      </div>
    </template>
    <template v-if="data && data.channel === 0">
      <div class="notOpen">影厅暂未开放</div>
    </template>
    <!-- </van-list> -->
    <van-popup v-model:show="show">
      <div class="detailed">
        <div class="ticket_name">
          <span>{{ moreData.filmName }}</span>
          <van-icon name="cross" @click="show = false" />
        </div>
        <div class="more_text">{{ moreData.filmIntroduction }}</div>
      </div>
    </van-popup>
    <MyNotice ref="myNotice" />
    <MyTips ref="myTips" :content="msg" toPath="/" buttonText="去预约" />
  </div>
</template>
<script>
import {
  //  cinema_list,
  filmList,
} from '@/api/ShadowTicket';
import { config_get, reservation } from '@/api/MovieTicketReservation';
import MyMore from '@/components/MyMore.vue';
import MyNotice from '@/components/MyNotice.vue';
import MyTips from '@/components/MyTips.vue';
export default {
  name: 'ShadowTicket',
  data() {
    return {
      type: '',
      dateList: [],
      dateActive: 0,
      // tabsActive: null,
      // tabsList: [],
      ticketList: [],
      show: false,
      moreData: {},
      api: process.env.VUE_APP_BASE_API + '/profile',
      msg: '',
    };
  },
  components: { MyMore, MyNotice, MyTips },
  props: {
    clickRightNum: {
      type: Number,
      default: () => 0,
    },
  },
  created() {
    if (this.$route.query.type === '4D影票购买') {
      this.type = '4D影院';
    } else if (this.$route.query.type === '球幕影票购买') {
      this.type = '球幕影院';
    }
    this.getConfig();
    // this.getCinemaList();
  },
  mounted() {},
  methods: {
    async getConfig() {
      const { data } = await config_get();
      this.data = data;
      this.getDate();
    },
    async getDate() {
      this.dateList = [];
      let maxDay = this.data.maxDay;
      let weekDays = ['日', '一', '二', '三', '四', '五', '六'];
      let weekTime = [];
      const monday = new Date(new Date().toLocaleDateString()).getTime();
      for (let i = 0; i < maxDay; i++) {
        weekTime.push(monday + i * 24 * 60 * 60 * 1000);
      }
      weekTime.map((v, index) => {
        let day = new Date(v).getDay();
        let dayTime = this.getNowFormatDate(new Date(v));
        this.dateList.push({
          dayTime,
          day,
          dayTit: index === 0 ? '今日' : weekDays[day],
          date: new Date(v).getDate(),
        });
      });
      this.getTicketList();
    },
    getNowFormatDate(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = '0' + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate;
      }
      var currentdate = year + '-' + month + '-' + strDate;
      return currentdate;
    },
    // async getCinemaList() {
    //   let { data } = await cinema_list();
    //   this.tabsList = data;
    //   this.tabsActive = this.tabsList[0].cinemaName || null;
    // },
    //选择日期
    onDate(index) {
      if (this.dateActive !== index) {
        this.dateActive = index;
        this.getTicketList();
      }
    },
    //点击更多
    onMore(data) {
      this.moreData = data;
      this.show = true;
    },
    //点击立即预约
    async onReservation(item) {
      let { dayTime, id } = item;
      await reservation({ dayTime, reorderInfoId: id });
      this.$router.replace({ path: '/movieTicketReservation', query: { id } });
    },
    async getTicketList() {
      let { data } = await filmList({
        cinemaName: this.type,
        dayTime: this.dateList[this.dateActive].dayTime,
      });
      this.ticketList = data;
    },
  },
  watch: {
    //点击预约须知
    async clickRightNum() {
      this.$refs.myNotice.init('影票购买', false, this.data.notice);
    },
    // //监听影院切换
    // tabsActive() {
    //   this.getTicketList();
    // },
  },
};
</script>
<style lang="scss" scoped>
.shadowTicket {
  background: #f5f6fa;
  min-height: calc(100vh - 46px);
  ::v-deep .van-notice-bar {
    .van-notice-bar__content {
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
    }
  }
  .date_box {
    background: #fff;
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-bottom: 10px;
    .date_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #000;
      font-weight: 400;
      span {
        font-size: 12px;
      }
      div {
        margin-top: 6px;
        font-size: 16px;
        border-radius: 50%;
        border: 1px solid #aaa;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
      }
      .dateActive {
        background-color: #076cfa;
        border: 1px solid #076cfa;
        color: #fff;
      }
    }
  }
  .van-tabs {
    border-bottom: 1px solid #ccc;
  }
  .notOpen {
    text-align: center;
    margin-top: 100px;
  }
  .ticket_item {
    padding: 15px 10px;
    background: #fff;
    display: flex;
    img {
      min-width: 120px;
      width: 120px;
      height: 120px;
      object-fit: cover;
    }
    .ticket {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      h4 {
        font-weight: 400;
        color: #333;
        font-size: 14px;
        line-height: 24px;
      }
      p {
        font-weight: 400;
        font-size: 12px;
        color: #666;
        line-height: 24px;
      }
      .btn_box {
        display: flex;
        justify-content: space-between;
        > p {
          > span {
            margin-right: 15px;
          }
        }
      }
    }
  }
  .ticket_item + .ticket_item {
    border-top: 1px solid #ccc;
  }
  .nothing {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    img {
      width: 60%;
    }
    span {
      font-size: 16px;
      color: #999;
    }
  }
  .detailed {
    width: 270px;
    .ticket_name {
      position: relative;
      padding: 5px;
      font-size: 16px;
      color: #333333;
      text-align: center;
      line-height: 30px;
      border-bottom: 1px solid #aaa;
      i {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
      }
    }
    .more_text {
      padding: 10px 20px;
      font-weight: 400;
      font-size: 13px;
      color: #333333;
      line-height: 28px;
      max-height: 70vh;
      overflow-y: auto;
    }
  }
}
</style>
