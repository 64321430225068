<template>
  <div class="myMore">
    <div class="box__text">
      <div class="box__inner">
        {{ moreData.filmIntroduction.substr(0, 34)
        }}<template v-if="moreData.filmIntroduction.length > 34"
          ><span>...</span>
          <div class="box__more">
            <span @click="onMore">更多</span>
          </div></template
        >
      </div>
    </div>
    <!-- <div class="box__abs">
      <div class="box__fake-text">
        {{ moreData.filmIntroduction }}
      </div>
      <div class="box__placeholder"></div>
      <div class="box__more"><span @click="onMore">更多</span></div>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    moreData: {
      type: Object,
      default: () => {},
    },
  },
  created() {},
  mounted() {},
  methods: {
    onMore() {
      this.$emit("onMore", this.moreData);
    },
  },
};
</script>
<style lang="scss" scoped>
.myMore {
  position: relative;
  overflow: hidden;
  .box__text {
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 0;
    font-size: 40px;
    letter-spacing: 0;
    color: transparent;
    height: 40px;
    /*   background: pink; */
    text-align: justify;
    .box__inner {
      height: 40px;
      position: relative;
      font-size: 12px;
      line-height: 20px;
      color: #000;
      .box__more {
        background-color: #fff;
        position: absolute;
        right: 0;
        top: 20px;
        text-align: right;
        font-size: 12px;
        span {
          display: block;
          line-height: 20px;
          height: 20px;
          color: #076cfa;
        }
      }
    }
  }
  // .box__abs {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 40px;
  //   .box__fake-text {
  //     width: 100%;
  //     margin-left: -60px;
  //     line-height: 20px;
  //     float: right;
  //     color: transparent;
  //   }
  //   .box__placeholder {
  //     width: 60px;
  //     height: 60px;
  //     float: right;
  //     /*   background: gray; */
  //     opacity: 0.5;
  //   }
  //   .box__more {
  //     background-color: #fff;
  //     position: relative;
  //     left: 100%;
  //     transform: translate(-100%, -205%);
  //     right: 0;
  //     width: 46px;
  //     height: 20px;
  //     line-height: 20px;
  //     text-align: right;
  //     // float: right;
  //     font-size: 12px;
  //     span {
  //       color: #fd4d4f;
  //       margin-left: 1px;
  //     }
  //   }
  // }
}
</style>
