import request from "@/utils/request";

// 影片列表
export const filmList = (params) => {
  return request({
    url: "/we_chat/show_ticket/list",
    method: "get",
    params,
  });
};

// 影厅列表
export const cinema_list = () => {
  return request({
    url: "/admin/show_ticket/cinema/list",
    method: "get",
  });
};
